<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 07:38:22
 * @ Description: Component displaying the individual profile experience tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Add Experience Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                v-if="!showAddEditExperienceBoolean"
                @click="showAddEditExperience(true)"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add Experience</span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Experience Button -->

            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Experience -->
                <v-col
                    v-if="experiences === null || experiences.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No experience added
                </v-col>
                <!-- No Experience -->

                <!-- Experience -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in experiences"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="170px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="6"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiBriefcaseOutline }}
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right pb-0"
                            >
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(menuItem, menuIndex) in menuItems"
                                            :key="menuIndex"
                                            @click="menuItemAction(menuItem, item)"
                                        >
                                            <v-list-item-title
                                                class="os-12-sb"
                                            >
                                                {{ menuItem }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0 text-truncate"
                                cols="12"
                            >
                                {{ item.role }}
                                <br />
                                <span class="os-14-r">({{ item.experience_level.name }})</span>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                class="os-12-sb text-center"
                                style="color: #2E567A; line-height: 1;"
                                cols="5"
                            >
                                {{ item.employment_start }}
                                <br /> -
                                <br /> <span v-if="item.employment_end">{{ item.employment_end }}</span><span v-else>Present</span>
                            </v-col>
                            <v-col
                                class="os-10-r text-right text-truncate"
                                style="color: #8F9BB3;"
                                align-self="center"
                                cols="7"
                            >
                                {{ item.name }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Experience -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Experience
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                                v-model="search"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- Experience -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="experienceTableHeaders"
                        :items="experiences"
                        item-key="id"
                        hide-default-footer
                        class="experience-table"
                        no-data-text="No experience added"
                        :no-results-text="noResultsText"
                        :search="search"
                    >
                        <template v-slot:[`item.role`]="{ item }">
                            {{ item.role }}
                        </template>
                        <template v-slot:[`item.experience_level`]="{ item }">
                            {{ item.experience_level.name }}
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                            {{ item.name }}
                        </template>
                        <template v-slot:[`item.employment_start`]="{ item }">
                            {{ item.employment_start }}
                        </template>
                        <template v-slot:[`item.employment_end`]="{ item }">
                            <span v-if="item.employment_end">{{ item.employment_end }}</span>
                            <span v-else>-</span>
                        </template>
                        <template v-slot:[`item.action`]>
                            <v-btn
                                icon
                                @click="menuItemAction ('Edit', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiArrowRightThinCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <!-- <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiPrinterOutline }}
                                </v-icon>
                            </v-btn> -->
                            <v-btn
                                icon
                                @click="menuItemAction ('Delete', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiTrashCanOutline }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Experience -->
            </v-row>
            <!-- List View -->

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddEditExperienceBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Experience
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- Experience Form -->
                        <v-col
                            cols="7"
                        >
                            <v-form
                                v-model="validExperienceForm"
                                ref="experienceForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="pb-0 os-15-sb"
                                    >
                                        Role<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :rules="nameRules"
                                            counter="50"
                                            v-model="experienceToUpsert.role"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Experience Level<span style="color: red;">*</span>
                                        <br />
                                        <v-select
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :items="experienceLevels"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            :rules="selectRules"
                                            v-model="experienceToUpsert.experience_level"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Employer Name<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :rules="nameRules"
                                            counter="50"
                                            v-model="experienceToUpsert.name"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Employment Start<span style="color: red;">*</span>
                                        <br />
                                        <v-menu
                                            v-model="startDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    v-model="experienceToUpsert.employment_start"
                                                    :rules="startDateRules"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker
                                                v-model="experienceToUpsert.employment_start"
                                                no-title
                                                scrollable
                                                color="#2E567A"
                                                @input="startDateMenu = false"
                                            />
                                        </v-menu>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Employment End
                                        <br />
                                        <v-menu
                                            v-model="endDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    v-model="experienceToUpsert.employment_end"
                                                    :rules="endDateRules"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :append-icon="mdiClose"
                                                    @click:append="experienceToUpsert.employment_end = null"
                                                />
                                            </template>
                                            <v-date-picker
                                                v-model="experienceToUpsert.employment_end"
                                                no-title
                                                scrollable
                                                color="#2E567A"
                                                @input="endDateMenu = false"
                                            />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- Experience Form -->

                        <!-- Add/Edit/Cancel Buttons -->
                        <v-col
                            align-self="end"
                            class="pb-7"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="upsertExperience"
                                :disabled="!validExperienceForm"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Experience
                                <v-icon
                                    class="ml-1"
                                    v-if="action === 'add'"
                                >
                                    {{ mdiPlusCircleOutline }}
                                </v-icon>
                                <v-icon
                                    class="ml-1"
                                    v-else-if="action === 'edit'"
                                >
                                    {{ mdiCircleEditOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb"
                                color="#8F9BB3"
                                @click="showAddEditExperience(false)"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <!-- Add/Edit/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddEditExperienceBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-form
                        v-model="validExperienceForm"
                        ref="experienceForm"
                    >
                        <v-row>
                            <!-- Heading -->
                            <v-col
                                cols="12"
                                class="os-22-sb"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Experience
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <!-- Heading -->

                            <!-- Experience Form -->
                            <v-col
                                cols="12"
                                class="pb-0 os-15-sb"
                            >
                                Role<span style="color: red;">*</span>
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :rules="nameRules"
                                    counter="50"
                                    v-model="experienceToUpsert.role"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Experience Level<span style="color: red;">*</span>
                                <br />
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="experienceLevels"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :rules="selectRules"
                                    v-model="experienceToUpsert.experience_level"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Employer Name<span style="color: red;">*</span>
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :rules="nameRules"
                                    counter="50"
                                    v-model="experienceToUpsert.name"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Employment Start<span style="color: red;">*</span>
                                <br />
                                <v-menu
                                    v-model="startDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="experienceToUpsert.employment_start"
                                            :rules="startDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="experienceToUpsert.employment_start"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="startDateMenu = false"
                                    />
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Employment End
                                <br />
                                <v-menu
                                    v-model="endDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="experienceToUpsert.employment_end"
                                            :rules="endDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="mdiClose"
                                            @click:append="experienceToUpsert.employment_end = null"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="experienceToUpsert.employment_end"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="endDateMenu = false"
                                    />
                                </v-menu>
                            </v-col>
                            <!-- Experience Form -->

                            <!-- Add/Edit/Cancel Buttons -->
                            <v-col
                                cols="12"
                                class="text-right"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="upsertExperience"
                                    :disabled="!validExperienceForm"
                                >
                                    <span v-if="!$store.state.general.customBreakpoints.xxs"><span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span></span>
                                    <v-icon
                                        class="ml-1"
                                        v-if="action === 'add'"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                    <v-icon
                                        class="ml-1"
                                        v-else-if="action === 'edit'"
                                    >
                                        {{ mdiCircleEditOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    @click="showAddEditExperience(false)"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>
                            <!-- Add/Edit/Cancel Buttons -->
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Experience'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </v-row>
</template>
<script>
    import { IndividualProfileExperienceController, LookupExperienceController } from '@/logic/controller/index.controller.js'
    import { mdiPlusCircleOutline, mdiCircleEditOutline, mdiClose, mdiDotsHorizontal, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiMagnify, mdiBriefcaseOutline } from '@mdi/js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'

    export default {
        name: 'ProfileIndividualExperienceTabComponent.vue',

        components: {
            GeneralConfirmDeleteOverlayComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            nameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            startDateRules () {
                if (this.experienceToUpsert.employment_start === null) return [ 'Required field' ]
                else if (this.experienceToUpsert.employment_start.replace(/\s/g, '') === '') return [ 'Invalid Date' ]
                else if (this.experienceToUpsert.employment_end !== null && new Date(this.experienceToUpsert.employment_start.replace(/-/g, '/')).getTime() > new Date(this.experienceToUpsert.employment_end.replace(/-/g, '/')).getTime()) return [ 'Start must be before end' ]
                else return []
            },

            endDateRules () {
                if (this.experienceToUpsert.employment_end === null) return []
                else if (this.experienceToUpsert.employment_end.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (this.experienceToUpsert.employment_start !== null && new Date(this.experienceToUpsert.employment_end.replace(/-/g, '/')).getTime() < new Date(this.experienceToUpsert.employment_start.replace(/-/g, '/')).getTime()) return [ 'End must be after start' ]
                else return []
            }
        },

        data () {
            return {
                mdiPlusCircleOutline,
                mdiCircleEditOutline,
                mdiClose,
                mdiDotsHorizontal,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiMagnify,
                mdiBriefcaseOutline,
                showAddEditExperienceBoolean: false,
                experienceTableHeaders: [
                    {
                        text: 'Role',
                        value: 'role'
                    },
                    {
                        text: 'Experience Level',
                        value: 'experience_level.name'
                    },
                    {
                        text: 'Employer',
                        value: 'name'
                    },
                    {
                        text: 'Employment Start Date',
                        value: 'employment_start'
                    },
                    {
                        text: 'Employment End Date',
                        value: 'employment_end'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                experiences: [],
                menuItems: [
                    'Edit',
                    // 'Print',
                    'Delete'
                ],
                lookupExperienceController: null,
                individualProfileExperienceController: null,
                experienceLevels: [],
                experienceToUpsert: {
                    user_id: this.$store.state.user.userData.id,
                    name: '',
                    role: '',
                    employment_start: null,
                    employment_end: null,
                    experience_level: {}
                },
                action: 'add',
                validExperienceForm: false,
                startDateMenu: false,
                endDateMenu: false,
                search: '',
                showDeleteConfirmationOverlayBoolean: false,
                experienceToDelete: null
            }
        },

        beforeMount () {
            this.lookupExperienceController = new LookupExperienceController()
            this.individualProfileExperienceController = new IndividualProfileExperienceController()
        },

        async mounted () {
            await this.getExperienceLevels()
            await this.getProfileExperience()
        },

        methods: {
            async getProfileExperience () {
                try {
                    this.experiences = await this.individualProfileExperienceController.retrieve(this.$store.state.user.userData.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getExperienceLevels () {
                try {
                    this.experienceLevels = await this.lookupExperienceController.getExperienceLevels()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertExperience () {
                try {
                    this.experiences = await this.individualProfileExperienceController.upsert(this.experienceToUpsert)

                    if (this.action === 'edit') {
                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    }

                    this.showAddEditExperienceBoolean = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteExperience (experience) {
                this.experienceToDelete = experience
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteExperience (experience) {
                try {
                    this.experiences = await this.individualProfileExperienceController.delete(experience)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.experienceToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showAddEditExperience (value) {
                this.showAddEditExperienceBoolean = value
                this.action = 'add'

                this.experienceToUpsert = {
                    user_id: this.$store.state.user.userData.id,
                    name: '',
                    role: '',
                    employment_start: null,
                    employment_end: null,
                    experience_level: {}
                }
            },

            menuItemAction (action, item) {
                if (action === 'Edit') {
                    Object.assign(this.experienceToUpsert, item)
                    this.action = 'edit'
                    this.showAddEditExperienceBoolean = true
                } else if (action === 'Delete') this.confirmDeleteExperience(item)
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteExperience(this.experienceToDelete)
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> .experience-table th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> .experience-table td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> .experience-table thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
